import React, { useContext } from 'react'
import { Footer } from 'react-bulma-components'
import { Container } from 'react-bulma-components'
import Logo from '../images/assets/logo.svg'
import styled from 'styled-components'
import { device } from '../utils/device'
import { SiteContext } from '../layouts'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StyledLogo = styled(Logo)`
    height: 50px;

    path,
    polygon {
        ${(props) => (props.color ? 'fill:' + props.color : null)}
    }
`

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const StyledFooterText = styled.div`
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #836969;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    font-weight: 100 !important;
    a {
        color: #836969;
        ${(props) => (props.color ? 'color:' + props.color : null)}
    }
    ${(props) => (props.color ? 'color:' + props.color : null)}
`
const StyledFooter = styled(Footer)`
    && {
        padding: 3rem 1.5rem 1rem;
        @media ${device.mobile} {
            padding: 3rem 1.5rem 1rem;
        }
        ${(props) => (props.color ? 'background:' + props.color : null)}
    }
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 500px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

const StyledSocial = styled(Footer)`
    && {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-top: 1rem;

        @media ${device.desktop} {
            display: flex;
        }

        @media ${device.widescreen} {
            display: flex;
        }

        background-color: rgba(255, 255, 255, 0);
    }
`

/*
export default class KSKFooter extends React.Component {
    

    render() {
        return (
            <StyledFooter>
                <StyledContainer>
                    <LogoContainer>
                        <StyledLogo />
                    </LogoContainer>
                    <StyledFooterText>
                        <a href="tel:041341449">041 341 449</a> |{' '}
                        <a href="mailto:info@kisik.si">info@kisik.si</a> |{' '}
                        {new Date().getFullYear()}
                    </StyledFooterText>
                </StyledContainer>
            </StyledFooter>
        )
    }
}*/

export default function KSKFooter() {
    const { t } = useTranslation()
    const context = useContext(SiteContext)

    const color = () => {
        switch (context.path) {
            case '/kisik-OLD/':
                return { background: '#01B5CC', text: 'white', logo: 'white' }
            case '/soncna-elektrarna/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: 'white',
                }
            case '/en/soncna-elektrarna/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: 'white',
                }
            case '/mikro-elektrarna/':
            case '/energetska-skupnost/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/en/energetska-skupnost/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/industrija/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/en/industrija/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/vzdrzevanje-svetovanje/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/en/vzdrzevanje-svetovanje/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/kisik/':
                return {
                    background: 'rgba(167,165,210,1)',
                    text: 'white',
                    logo: 'white',
                }
            case '/en/kisik/':
                return {
                    background: 'rgba(167,165,210,1)',
                    text: 'white',
                    logo: 'white',
                }
            case '/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            case '/en/':
                return {
                    background: 'rgba(0,0,0,0)',
                    text: 'black',
                    logo: '#FFFFFF',
                }
            default:
                return { background: null, text: null }
        }
    }

    const { background, text, logo } = color()

    return (
        <StyledFooter color={background}>
            <StyledContainer>
                <LogoContainer>
                    <StyledLogo color={logo} />
                </LogoContainer>
                <StyledFooterText color={text}>
                    <a href="tel:041341449">041 341 449</a> |{' '}
                    <a href="mailto:info@kisik.si">info@kisik.si</a> |{' '}
                    {new Date().getFullYear()}
                </StyledFooterText>
                <StyledSocial>
                    <a
                        href="https://www.linkedin.com/company/kisiksi/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <StaticImage
                            src="../images/linkedin.png"
                            alt="linkedin"
                            height={24}
                        />
                    </a>

                    <a
                        href="https://www.facebook.com/profile.php?id=100087894267904"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginLeft: '1em',
                            marginRight: '1em',
                            marginBottom: '1em',
                        }}
                    >
                        <StaticImage
                            src="../images/fb.png"
                            alt="facebook"
                            height={24}
                        />
                    </a>

                    <a
                        href="https://www.instagram.com/kisik.si/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            marginBottom: '1em',
                        }}
                    >
                        <StaticImage
                            src="../images/ig.png"
                            alt="instagram"
                            linkto="https://www.instagram.com/kisik.si/"
                            height={24}
                        />
                    </a>
                </StyledSocial>
                <StyledFooterText
                    color={text}
                    style={{ fontSize: '0.6rem', marginTop: '0.2rem' }}
                >
                    <Link to={'/legal'}>{t('footer.legal')}</Link>
                </StyledFooterText>
            </StyledContainer>
        </StyledFooter>
    )
}
