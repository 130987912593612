import React, { useContext, useState, Suspense} from 'react'
import styled from 'styled-components'
import Navigation from './Navigation'
import ModalMenu from './ModalMenu'
import { SiteContext } from '../../layouts'
import Skybox from './Skybox'


//const Skybox = loadable(() => import('./Skybox'),{ ssr: false })
const Skybox2 = React.lazy(() => import('./Skybox'));


const StyledHeader = styled.header`
    display: flex;
    min-height: 100px;
    justify-content: center;
`
/*
const SkyboxWrapper = styled.div`
    display: block;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: height 0.7s ease-out;
    height: ${(props) => {
        switch (true) {
            case '/' === props.path:
                return '800px'
            case '/pvcalc' === props.path:
                return '100vh'
            case '/cenik' === props.path:
                return '800px'
            case props.path.includes('kisik'):
                return '800px'
            case window.innerWidth < 950:
                return '115px'
            default:
                return '85px'
        }
    }};
`*/

export default function Header() {
    const context = useContext(SiteContext)
    const [modalMenuOpen, setModalMenuOpen] = useState(false)

    return (
        <StyledHeader>
            <Navigation setModalMenuOpen={setModalMenuOpen}/>
            <ModalMenu modalMenuOpen={modalMenuOpen} setModalMenuOpen={setModalMenuOpen}/>

                <Skybox2 />

        </StyledHeader>
    )
}
