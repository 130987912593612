import React, { useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { SiteContext } from '../../layouts'
import { device } from '../../utils/device'
import Paper from 'paper'
import { pathLib, getTheme } from './skyLibrary'

const SkyboxWrap = styled.div`
    height: 100vh;
    min-height: 600px; // Edge case: Mac Air, narrow aspect low-res hidpi screen, bottom ov canvas gets clipped
    position: absolute;
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    overflow: hidden;

    canvas[resize] {
        width: 100%;
        height: 100%;
    }
`

export const Skybox = (props) => {
    const context = useContext(SiteContext)
    const canvasRef = useRef(null)
    const hCurveRef = useRef(null)

    useEffect(() => {
        //for context changes (url)
        if (hCurveRef.current !== null) {
            hCurveRef.current.changePath(context.path)
        }
    }, [context])

    useEffect(() => {
        let paper = require('paper/dist/paper-full')

        /*canvasRef.current.width = window.innerWidth
        canvasRef.current.height = window.innerHeight*/

        let theme = getTheme()
        // Set up Canvas and Paperjs
        const canvas = canvasRef.current
        paper.setup(canvas)
        let cWidth =
            canvas.getBoundingClientRect().width > 1200
                ? canvas.getBoundingClientRect().width
                : 1200
        let cHeight = canvas.getBoundingClientRect().height

        // Init curve
        const hCurve = new HeadCurve(
            paper,
            window.location.pathname,
            cWidth,
            cHeight
        )
        // Init gradient
        const gradBg = new GradientBg(paper, cWidth, cHeight)
        //Init group
        const group = new Paper.Group([hCurve.path, gradBg.rec])
        hCurveRef.current = hCurve
        group.clipped = true

        paper.view.onResize = function (event) {
            let newWidth = window.innerWidth > 1200 ? window.innerWidth : 1200
            hCurve.handleResize(newWidth)
            gradBg.handleResize()
        }

        if (process.env.NODE_ENV === 'development') {
            console.log(paper)
        }
    }, [])

    return (
        <SkyboxWrap id="skybox">
            <canvas ref={canvasRef} {...props} id="canvas" resize="true" />
        </SkyboxWrap>
    )
}

export default Skybox

class GradientBg {
    constructor(paper, width, height) {
        this.cWidth = width
        this.cHeight = height
        this.paper = paper
        this.cGrad = 'kisikBlue'
        this.rec = new paper.Shape.Rectangle(
            [0, 0],
            new paper.Size([3500, height])
        )
        this.rec.setPosition([window.innerWidth / 2, this.cHeight / 2])
        //this.fullySelected = 'true'
        this.doGrad()
    }

    handleResize() {
        this.rec.setPosition([window.innerWidth / 2, this.cHeight / 2])
    }

    doGrad() {
        this.rec.fillColor = this.gradLib(this.cGrad)
    }

    gradLib(grad) {
        const gLib = {
            blue1: {
                stops: [
                    ['#4ECDC4', 0.05],
                    ['#556270', 1],
                ],
            },
            kisikBlue: {
                gradient: {
                    stops: [
                        ['#017181', 0],
                        ['#01AFC8', 0.51],
                    ],
                },
                origin: [0, -630],
                destination: [2500, 1377],
            },
            kisikDark: {
                gradient: {
                    stops: [
                        ['#7f5a83', 0],
                        ['#5a4661', 0.18],
                        ['#3f3848', 0.35],
                        ['#2e2f39', 0.49],
                        ['#282c34', 0.61],
                    ],
                },
                origin: [1377, 2500],
                destination: [-630, 0],
            },

            /*
            <defs>
                    <linearGradient
                        id="linear-gradient-light"
                        x1="1105.22"
                        y1="-631.15"
                        x2="3045.04"
                        y2="1377.33"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.26" stopColor="#89f7fe" />
                        <stop offset="0.76" stopColor="#66a6ff" />
                    </linearGradient>
                    <linearGradient
                        id="linear-gradient-dark"
                        x1="2585.17"
                        y1="1275.41"
                        x2="1479.91"
                        y2="-638.96"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#7f5a83" />
                        <stop offset="0.18" stopColor="#5a4661" />
                        <stop offset="0.35" stopColor="#3f3848" />
                        <stop offset="0.49" stopColor="#2e2f39" />
                        <stop offset="0.61" stopColor="#282c34" />
                    </linearGradient>

             */
        }
        return gLib[grad]
    }
}

class HeadCurve {
    //This class will contain everything needed to render the main vector curve path
    constructor(paper, sPath, cWidth, cHeight) {
        //we must pass it a group, into which we insert the path
        this.paper = paper
        this.cWidth = cWidth
        this.cHeight = cHeight
        this.currentPath = sPath
        this.path = new this.paper.Path({
            closed: 'true',
        })

        this.drawCurve(this.currentPath)
        if (process.env.NODE_ENV === 'development') {
            this.path.fullySelected = true
        }
    }

    drawCurve(pShape) {
        let segments = this.constructSegments(pShape)
        this.path.addSegments(segments)
    }

    changePath(newCurve) {
        //This handles transitions
        this.currentPath = newCurve
        const pathFrom = this.path.clone({ insert: false })
        const pathTo = new this.paper.Path({
            segments: this.constructSegments(newCurve),
            insert: false,
        })

        this.path.tween({
            easing: (x) => this.easeOutBack(x),
            duration: 800,
        }).onUpdate = (event) => {
            this.path.interpolate(pathFrom, pathTo, event.factor)
        }
    }

    easeOutElastic(x) {
        const c4 = (2 * Math.PI) / 3

        return x === 0
            ? 0
            : x === 1
            ? 1
            : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1
    }

    easeOutBack(x) {
        const c1 = 1.70158
        const c3 = c1 + 1

        return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2)
    }

    getPathBoundingRec() {
        return this.path.bounds
    }

    handleResize(newWidth) {
        this.cWidth = newWidth
        this.cHeight = window.innerHeight
        this.path.removeSegments()
        this.path.addSegments(this.constructSegments(this.currentPath))
        if (process.env.NODE_ENV === 'development') {
            this.path.fullySelected = true
        }
    }

    toRad(deg) {
        let pi = Math.PI
        return deg * (pi / 180)
    }

    getPath(pShape) {
        const preparePath = (pShape, pathName) => {
            //transform from relative dimension to actual position
            let ar = pShape.map((pointObj, index) => {
                let handle = [
                    ((Math.cos(this.toRad(pointObj.hVector[0])) *
                        pointObj.hVector[1]) /
                        100) *
                        this.cWidth,
                    ((Math.sin(this.toRad(pointObj.hVector[0])) *
                        pointObj.hVector[1]) /
                        100) *
                        this.cHeight,
                ]
                return {
                    point: [
                        (pointObj.point[0] / 100) * this.cWidth,
                        heightAdjust(pointObj.point[1], pathName),
                    ],
                    hOut:
                        index === pShape.length - 1
                            ? [0, 0]
                            : [handle[0], handle[1]],
                    hIn: index === 0 ? [0, 0] : [-handle[0], -handle[1]],
                }
            })
            return ar
        }
        const heightAdjust = (yPoint, pathName) => {
            //This is to make the curve height less on mobile, except when displaying the LINE segment form
            if (window.innerWidth < 769 && pathName == 'home') {
                return yPoint - 300
            }
            return window.innerWidth < 769 && pathName !== 'line'
                ? yPoint - 150
                : yPoint
        }
        const { pathName, pathPoints } = pathLib.returnPath(pShape)
        return preparePath(pathPoints, pathName)
    }

    constructSegments(pShape) {
        //Here we assemble the path
        // We need: Top-Left edge segment, Zero segment, -- Generated Path segments -- , End Segment, Top-Right edge segment
        //Load path we will use
        const p = this.getPath(pShape)
        const pSeg = p.map((point) => {
            return new Paper.Segment({
                point: [point.point[0], point.point[1]],
                handleOut: [point.hOut[0], point.hOut[1]],
                handleIn: [point.hIn[0], point.hIn[1]],
            })
        })
        let topLeft = new Paper.Segment({
            point: [0, 0],
        })
        let topRight = new Paper.Segment({
            point: [this.cWidth, 0],
        })
        return [topLeft, ...pSeg, topRight]
    }
}

const pathLibrary = {
    item: {
        cenik: '<path id="cenik" class="cls-2" d="M66.23,0s80.43,136.29,262,127c254-13,507,305,808,307s404-128,680-123,434.25,240.3,606,243c191,3,449-166,696-168s1044,304,1044,304V0Z"/>',
        home: '<path id="home" class="cls-1" d="M66.23,0V690.58S432,702.35,866.71,529.88c263.68-104.61,593.41,42.08,800.73,27.26,181-12.95,178.54-61.69,347.77-91.17s266.94,7.16,503.74-122.81c172.6-94.74,451.68-314.28,856.62-245.79S4162.23,109,4162.23,0Z"/>',
        pvcalc: '<path id="pvcalc" class="cls-3" d="M66.23,0s-161.57,760.29,20,751c254-13,707,73,1008,75s540,27,816,32,452.25-2.7,624,0c191,3,569,42,816,40s812-208,812-208V0Z"/>',
    },
    d: {
        cenik: 'M66.23,0s80.43,136.29,262,127c254-13,507,305,808,307s404-128,680-123,434.25,240.3,606,243c191,3,449-166,696-168s1044,304,1044,304V0Z',
        home: 'M66.23,0V690.58S432,702.35,866.71,529.88c263.68-104.61,593.41,42.08,800.73,27.26,181-12.95,178.54-61.69,347.77-91.17s266.94,7.16,503.74-122.81c172.6-94.74,451.68-314.28,856.62-245.79S4162.23,109,4162.23,0Z',
        pvcalc: 'M66.23,0s-161.57,760.29,20,751c254-13,707,73,1008,75s540,27,816,32,452.25-2.7,624,0c191,3,569,42,816,40s812-208,812-208V0Z',
        kisik: 'M0,0V690.58s365.72,11.77,800.48-160.7c263.68-104.61,473.05,169,652.52,64.12,380-222,400.77-39.52,570-69s275.1-127.61,541-80c229,41,298.06-168.49,703-100S4096,109,4096,0Z',
        elipse: 'M0,0V256S339,356.92,803,416c267,34,524.37,51.47,732,61,196,9,352.9,12.1,522,13,188,1,260,2,525-11,273.14-13.4,1013-74,1416-186,395.69-110,98-184,98-293Z',
        sidewaysElipse:
            'M0,0V116S360.54,246.48,820,334c273,52,518.4,86.27,725,109,200,22,343.24,36.23,512,47,188,12,265.76,17.35,531,24,279,7,1024-3,1468-102,400.85-89.38,40-303,40-412Z',
        about: 'M0,0V116S360.54,246.48,820,334c273,52,518.4,86.27,725,109,200,22,343.24,36.23,512,47,188,12,265.76,17.35,531,24,279,7,1024-3,1468-102,400.85-89.38,40-303,40-412Z',
        smallTopRect:
            'M110.7,0s-220.58,219.29-39,210c254-13,811-2,1112,0s644-16,920-11,634.24-9.7,806-7c191,3,465,2,712,0s585,18,585,18V0Z',
        smallTopRect2:
            'M29.41,0s-103.57,387.29,78,378c254-13,766-2,1067,0s627-13,903-8,608.25-5.7,780-3c191,3,1115,13,1362,11s-94-173-94-173V0Z',
    },
    match: {
        '/': 'home',
        '/cenik': 'sidewaysElipse',
        '/pvcalc': 'pvcalc',
        '/kisik': 'kisik',
        '/about': 'about',
        default: 'smallTopRect',
    },
    returnPathOnFromMatch: (match) => {
        if (match.includes('/kisik')) {
            return pathLibrary.d.kisik
        }
        if (match in pathLibrary.match) {
            return pathLibrary.d[pathLibrary.match[match]]
        }
        return pathLibrary.d.smallTopRect
    },
}
