import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import Logo from '../../images/assets/logo.svg'
import { Navbar } from 'react-bulma-components'
import { device } from '../../utils/device'
import { Container } from 'react-bulma-components'
import { SiteContext } from '../../layouts'
import { useI18next } from 'gatsby-plugin-react-i18next'

const StyledBurger = styled(Navbar.Burger)`
    margin-right: 20px;
    @media ${device.mobile} {
        margin-right: 0;
    }
`
const StyledNavbar = styled(Navbar)`
    width: 100%;
    padding-top: 1rem;
    background: none !important;
    @media screen and (max-width: 1023px) {
        padding: 1rem 1.5rem 0 1.5rem;
    }

    img {
        max-height: 2rem !important;
    }

    .navbar-brand .navbar-item {
        padding: 0;
    }

    .navbar-item {
        font-size: 1.2rem;
        color: white;
        border-radius: 50px;
        font-family: Rubik, Nunito, sans-serif;

        &:hover {
        }
    }
`

const StyledNavBarStart = styled(Navbar.Container)`
    && {
        height: 42px;
        align-items: center;
    }
`

const StyledLogo = styled(Logo)`
    .cls-1 {
        fill: white;
    }
`

const StyledSocial = styled(Navbar.Container)`
    && {
        height: 42px;
        align-items: center;
    }

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.tablet} {
        display: none;
    }

    @media ${device.desktop} {
        display: none;
    }

    @media ${device.widescreen} {
        display: flex;
    }
`

function ReactiveLogo() {
    if (typeof window !== 'undefined') {
        if (window.location.pathname == '/') return ''
    }

    return (
        <Link to={'/'} className={'navbar-item'}>
            <StyledLogo alt="Kisik" width="152" height="38" />
        </Link>
    )
}

export default function ({ setModalMenuOpen }) {
    const context = useContext(SiteContext)

    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const { languages, originalPath, t, i18n, changeLanguage } = useI18next()
    return (
        <Container>
            <StyledNavbar className={context.theme === 'dark' && 'is-dark'}>
                <Navbar.Brand style={{ marginLeft: 0 }}>
                    <ReactiveLogo></ReactiveLogo>
                    <StyledBurger
                        onClick={() => {
                            setModalMenuOpen(true)
                        }}
                    />
                </Navbar.Brand>
                <Navbar.Menu style={{ alignItems: 'center' }}>
                    <StyledNavBarStart>
                        <Link
                            to={'/soncna-elektrarna'}
                            className={'navbar-item'}
                        >
                            {t('navigation.powerplant')}
                        </Link>
                        <Link
                            to={'/energetska-skupnost'}
                            className={'navbar-item'}
                        >
                            {t('navigation.community')}
                        </Link>
                        <Link to={'/industrija'} className={'navbar-item'}>
                            {t('navigation.industry')}
                        </Link>
                        <Link
                            to={'/vzdrzevanje-svetovanje'}
                            className={'navbar-item'}
                        >
                            {t('navigation.vzdrzevanje')}
                        </Link>
                        <Link to={'/blog'} className={'navbar-item'}>
                            {t('navigation.blog')}
                        </Link>
                        <Link to={'/kisik'} className={'navbar-item'}>
                            {t('navigation.about')}
                        </Link>
                    </StyledNavBarStart>
                    <StyledSocial>
                        <a
                            rel="noopener noreferrer"
                            className={'navbar-item'}
                            onClick={() => {
                                window.localStorage.setItem(
                                    'gatsby-i18next-language',
                                    i18n.language == 'en' ? 'si' : 'en'
                                )
                                changeLanguage(
                                    i18n.language == 'en' ? 'si' : 'en',
                                    originalPath
                                )
                            }}
                        >
                            <span
                                style={{
                                    display:
                                        i18n.language == 'en'
                                            ? 'block'
                                            : 'none',
                                }}
                            >
                                SI
                            </span>{' '}
                            <span
                                style={{
                                    display:
                                        i18n.language == 'si'
                                            ? 'block'
                                            : 'none',
                                }}
                            >
                                EN
                            </span>
                        </a>
                    </StyledSocial>
                </Navbar.Menu>
            </StyledNavbar>
        </Container>
    )
}
